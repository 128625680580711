export const deviceAirspanMetricsMap = {
  'device_phydlnew{cell=~"1"}': "Physical Downlink",
  'device_phyul{cell=~"1"}': "Physical Uplink",
  'device_dlper{cell=~"1"}': "Downlink Block Error Rate (BLER)",
  'device_ulcrcper{cell=~"1"}': "Uplink Block Error Rate",
  'device_ulmcs{cell=~"1"}': "Uplink Modulation and Coding Scheme",
  'device_dlmcscw_0{cell=~"1"}': "Downlink Modulation and Coding Scheme CW0",
  'device_dlmcscw_1{cell=~"1"}': "Downlink Modulation and Coding Scheme CW1",
  'device_dlcqicw_0{cell=~"1"}': "Downlink Channel Quality Indicator CW0",
  'device_dlcqicw_1{cell=~"1"}': "Downlink Channel Quality Indicator CW1",
  'device_c2i{cell=~"1"}': "Cell to Interference Ratio",
  'device_rssi{cell=~"1"}': "Received Signal Strength Indicator",
  'device_rirx{cell=~"1"}': "Rank Indicator - Receiver",
  'device_ritx{cell=~"1"}': "Rank Indicator - Transmit",
  'device_rnti{cell=~"1"}': 'Radio Network Temporary Identifier',

  'device_phydlnew{cell=~"2"}': "Physical Downlink (Cell 2)",
  'device_phyul{cell=~"2"}': "Physical Uplink (Cell 2)",
  'device_dlper{cell=~"2"}': "Downlink Block Error Rate (BLER) (Cell 2)",
  'device_ulcrcper{cell=~"2"}': "Uplink Block Error Rate (Cell 2)",
  'device_ulmcs{cell=~"2"}': "Uplink Modulation and Coding Scheme (Cell 2)",
  'device_dlmcscw_0{cell=~"2"}': "Downlink Modulation and Coding Scheme CW0 (Cell 2)",
  'device_dlmcscw_1{cell=~"2"}': "Downlink Modulation and Coding Scheme CW1 (Cell 2)",
  'device_dlcqicw_0{cell=~"2"}': "Downlink Channel Quality Indicator CW0 (Cell 2)",
  'device_dlcqicw_1{cell=~"2"}': "Downlink Channel Quality Indicator CW1 (Cell 2)",
  'device_c2i{cell=~"2"}': "Cell to Interference Ratio (Cell 2)",
  'device_rssi{cell=~"2"}': "Received Signal Strength Indicator (Cell 2)",
  'device_rirx{cell=~"2"}': "Rank Indicator - Receiver (Cell 2)",
  'device_ritx{cell=~"2"}': "Rank Indicator - Transmit (Cell 2)",
} as const;

export const deviceMetricsMap = {
  "ue_uplink_sinr": "Signal to Noise Ratio",
  "ue_pdownlink_cqi": "Channel Quality Indicator",
  "ue_pdownlink_mcs": "Modulation and Coding Scheme (Downlink)",
  "ue_uplink_mcs": "Modulation and Coding Scheme (Uplink)",
  "ue_p1downlink_bler": "Block Error Rate (Downlink)",
  "ue_uplink_bler": "Block Error Rate (Uplink)",
  "ue_pathloss": "Pathloss",
  "ue_rsrp0": "Received Signal Strength Indicator (Cell-1)",
  "ue_rsrp1": "Received Signal Strength Indicator (Cell-2)",
  "ue_rsrp2": "Received Signal Strength Indicator (Cell-3)",
  "ue_rsrp3": "Received Signal Strength Indicator (Cell-4)",
  "ue_rsrq": "Reference Signal Received Quality",
  "ue_sinr": "Signal to Noise Ratio Digi",
  "ue_rssi": "Received Signal Strength Indicator Digi",

  ...deviceAirspanMetricsMap
} as const;

export const deviceMetricsList = Object.keys(deviceMetricsMap);

const deviceMetricsForTable = {
  "ue_connection_state": "Connection State",
  "ue_downlink_rate": "Downlink Rate",
  "ue_eci": "ECI",
  "ue_id": "UE ID",

  "ue_p2downlink_bler": "Block Error Rate P2 (Downlink)",
  "ue_radio_connection": "Radio Connection",

  "ue_s1downlink_bler": "Block Error Rate S1 (Downlink)",
  "ue_s2downlink_bler": "Block Error Rate S2 (Downlink)",
  "ue_sdownlink_cqi": "Channel Quality Indicator (S-Downlink)",
  "ue_sdownlink_mcs": "Modulation and Coding Scheme (S-Downlink)",
  "ue_tx_power": "Transmit Power",
  "ue_uplink_rate": "Uplink Rate",
}

export const deviceMetricsListAll = [
  ...Object.keys(deviceMetricsForTable),
  ...deviceMetricsList,
]


type DeviceMetricsMap = typeof deviceMetricsMap;
type ReversedDeviceMetricsMap = {
  [K in DeviceMetricsMap[keyof DeviceMetricsMap]]: {
    [Key in keyof DeviceMetricsMap]: DeviceMetricsMap[Key] extends K ? Key : never;
  }[keyof DeviceMetricsMap];
};

export const DeviceMetricsKeys: ReversedDeviceMetricsMap = Object.entries(deviceMetricsMap).reduce((acc, [key, value]) => {
  // @ts-ignore
  acc[value] = key
  return acc;
}, {} as ReversedDeviceMetricsMap);

export type DeviceMetrics = {
  [key in keyof DeviceMetricsMap]?: number | undefined;
} & {
  [key in keyof typeof deviceMetricsForTable]?: number | undefined;
} & {
  [key: string]: number | undefined;
  timestamp?: number;
};


export type DeviceMetricsData = {
  [key in keyof DeviceMetricsMap]?: [number, number][]
} & {
  [key in keyof typeof deviceMetricsForTable]?: [number, number][];
} & {
  [key: string]: [number, number][];
} & {
  [key in keyof typeof networkMetricsMap]?: [number, number][];
};


export const networkMetricsMap = {
  device_upload_throughput: "Upload Throughput",
  device_download_throughput: "Download Throughput",
  sent_bytes: "Sent Bytes",
  received_bytes: "Received Bytes",
} as const;

export const networkMetricsList = Object.keys(networkMetricsMap);