import React, { useMemo } from 'react'
import "./Assistant.style.css";
import { Button, } from '@mui/material';

import { sendAssistantMessage } from './utils';
import { useAppDispatch, useAppSelector } from '~/store/hooks';

import { AgentIcon, Chatbot, useStreamAdapter } from '@h9/ui-lib';
import { utilityActions } from '~/store/context/utilitySlice';

export const CHATBOT_WIDTH = '25vw';
const BOT_NAME = 'Virtual Engineer';

const styles: Record<string, React.CSSProperties> = {
    button: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        margin: '1rem',
        zIndex: 1000,
    },
    chat: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: CHATBOT_WIDTH,
        transition: 'height 0.5s',
        boxShadow: '0 0 10px 0 rgba(0,0,0,.2)',
        borderRadius: '10px 10px 0 0',
        background: 'white',
        zIndex: 1000,
    },

} as const

type Props = {}

const Assistant = (props: Props) => {
    const dispatch = useAppDispatch();

    const open = useAppSelector((state) => state.utility.chatBot.open);
    const height = useAppSelector((state) => state.utility.chatBot.height);
    const maximized = useAppSelector((state) => state.utility.chatBot.maximize);

    const username = useAppSelector((state) => state.auth.user?.name);

    const setOpen = (open: boolean) => { dispatch({ type: 'utility/setChatBotOpen', payload: open }) }

    const adapter = useStreamAdapter(sendAssistantMessage)

    // Get the conversation from the session storage if it exists IF the chatbot is open
    const initConversation = useMemo(() => {
        if (!open) return undefined;
        return sessionStorage.getItem('chatHistory') ? JSON.parse(sessionStorage.getItem('chatHistory')!) : undefined;
    }, [open]);

    if (!open) {
        return (
            <Button
                aria-label="open"
                content={BOT_NAME}
                onClick={() => { setOpen(true) }}
                color='inherit'
                className='chatbot-fab' >
                <AgentIcon color='#226a58' size={28} />
            </Button>
        )
    }

    return (
        <div style={{
            ...styles.chat,
            height: height,
            borderRadius: maximized ? '0' : '10px 10px 0 0',
        }}>
            <Chatbot
                header={{ title: 'Virtual Engineer' }}
                inputOptions={{ prompt: 'Ask me anything...', sendOnSpeech: false }}
                initialMessages={initConversation}
                personaOptions={[
                    { name: 'Virtual Engineer', role: 'assistant', tagline: "Highway 9 Virtual Engineer" },
                    { name: username, role: 'user' },
                    { name: "Randle", role: 'assistant', color: "#ff3200" },
                    { name: "Caroline", role: 'assistant', color: "#5D0EAC" }
                ]}
                adapter={adapter}
                state={{
                    maximize: maximized,
                    onClose: () => { setOpen(false) },
                    onSizeChange: () => { dispatch(utilityActions.maximizeChatBot()) },
                }}
            />
        </div>
    )
}

export default Assistant