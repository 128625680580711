import { jsx as r, jsxs as t } from "react/jsx-runtime";
import { CircularProgress as d } from "@mui/material";
import { useChatbotContext as h } from "./hooks/index.js";
import "../../Chatbot.style-f63e71b5.js";
import "react";
import "../../map-85279df3.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Typography/Typography.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-84657d02.js";
import "moment-timezone";
import "../../runtime-9c5110e9.js";
import "../../TableContext-c60e49ca.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import v from "../../assets/icons/AgentIcon.js";
import "../../ChatbotContext-74017a6b.js";
import { c as i } from "../../createSvgIcon-0ab806e0.js";
const f = i(/* @__PURE__ */ r("path", {
  d: "M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22z"
}), "CloseFullscreen"), u = i(/* @__PURE__ */ r("path", {
  d: "M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z"
}), "OpenInFull"), z = i([/* @__PURE__ */ r("circle", {
  cx: "9",
  cy: "9",
  r: "4"
}, "0"), /* @__PURE__ */ r("path", {
  d: "M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}, "1")], "RecordVoiceOver"), L = i(/* @__PURE__ */ r("path", {
  d: "M12.99 9.18c0-.06.01-.12.01-.18 0-2.21-1.79-4-4-4-.06 0-.12.01-.18.01l4.17 4.17zm-6.1-3.56L4.27 3 3 4.27l2.62 2.62C5.23 7.5 5 8.22 5 9c0 2.21 1.79 4 4 4 .78 0 1.5-.23 2.11-.62L19.73 21 21 19.73l-8.62-8.62-5.49-5.49zM9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}), "VoiceOverOff"), K = ({ subtitle: l, title: m, component: s, maximize: a, onSizeChange: o, onClose: e }) => {
  const { isProcessing: p, transcription: c } = h();
  function n() {
    c.toggleTranscription(), c.enabled && c.stopSpeaking();
  }
  return s || /* @__PURE__ */ t("div", { className: "chatbot-header", children: [
    /* @__PURE__ */ t("div", { className: "title", children: [
      /* @__PURE__ */ t("span", { className: "chatbot-header-title", children: [
        /* @__PURE__ */ r(v, { size: 28 }),
        " ",
        m
      ] }),
      l && /* @__PURE__ */ r("span", { className: "subtitle", children: l })
    ] }),
    /* @__PURE__ */ t("div", { className: "chatbot-actions", children: [
      p && /* @__PURE__ */ r(d, { size: 23 }),
      /* @__PURE__ */ r("div", { className: "action", onClick: n, children: c.enabled ? /* @__PURE__ */ r(z, {}) : /* @__PURE__ */ r(L, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => o == null ? void 0 : o(!a), children: a ? /* @__PURE__ */ r(u, {}) : /* @__PURE__ */ r(f, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => e == null ? void 0 : e(), children: "✖" })
    ] })
  ] });
};
export {
  K as default
};
