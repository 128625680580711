import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const routeParamsRecords = new Map<string, string>();

/**
 * update URL with new query parameters without reloading the page or losing state in React Router
 * @param {{
 *  [key: string]: string | number | undefined;
 * }} params
 * @note if value is undefined, the key will be removed from the URL
 */

export const updateURL = (params: Record<string, string | number | undefined>) => {
    const url = new URL(window.location.href);
    // if key value is undefined, remove it from the URL
    Object.entries(params).forEach(([key, value]) => {
        if (value === undefined) {
            url.searchParams.delete(key);
        } else {
            url.searchParams.set(key, value.toString());
        }
    });
    window.history.replaceState(null, "", url.toString());

    // update the routeParamsRecords
    routeParamsRecords.set(url.pathname, url.search);
};

// history.listen is used to get the current location of the page and update the previous params
history.listen((location) => {
    // console.log("location", location, routeParamsRecords);
    if(routeParamsRecords.has(location.pathname)){
        const search = routeParamsRecords.get(location.pathname);
        const url = new URL(window.location.href);
        if(search){
            url.search = search;
            window.history.replaceState(null, "", url.toString());
        }
    }
});