/***************************************************
 *  Copyright 2024-2025 Highway9 Networks Inc.     *
 ***************************************************/
import { authenticationService } from "~/services";
import { StreamSend, Socket } from "@h9/ui-lib";

function getWebSocket() {
  const env = window.location.protocol === 'https:' ? `wss://` :  `ws://`
  const url = `${env}${window.location.host}/ws/chat/`;
  const connection =  new Socket();
  connection.connect(url , {
    token: authenticationService.currentUserValue?.token,
  });

  return connection;
}


const ws = getWebSocket();

const chatSplKeys = {
    agent :  ["RANDLE", "CAROLINE"],
    status : ["END", "START"],
    link : [ "/radios", "/devices", "/edges" ]
} as const;

type ChatSplKeys = keyof typeof chatSplKeys;



export const sendAssistantMessage: StreamSend = async (message, stream) => {
  try {
  ws.send(message);
  
  console.log("👤:", message)

  const res = await new Promise((resolve) => {
      let responseMessage = "";
      let agentName = "Virtual Engineer";
      ws.on('open', () => {
          stream.system?.("Connection established");
      })
      ws.on('message', (event) => {
          const response = event.data;
          const regex = /<<<(.*?)>>>/;  // Regex to extract SPECIAL messages
          const match = regex.exec(response);
          
          if (!match) {
              stream.next(response, agentName);
              responseMessage += response;
              return;
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_, extracted] = match;
          
                    // Get key value from extracted string
                    const [key , value] = extracted.split("=").map((v) => v.trim());

                    switch (key as ChatSplKeys) {
                        case "agent":
                            agentName = value;
                            break;
                        case "status":
                            if (value === "END") {
                                stream.complete();
                                
                                resolve({
                                    message: responseMessage,
                                    sender : agentName,
                                });
                                responseMessage = "";
                            }
                            break;
                        case "link":
                            stream.system?.("Redirecting to " + value);
                            break;
                        default:
                            break;
                    }
      })
      ws.on('error', (error) => {
          stream.error?.(error as any);
      })
      ws.on('close', () => {
          stream.system?.("Connection closed");
      })  
  }) as { message: string, sender: string };

  console.log("🤖:", res.message)

  return res;

} catch (error) {
  stream.error?.(error as any);
  throw error;
}
}


