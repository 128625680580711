import m, { jsxs as n, jsx as o } from "react/jsx-runtime";
import { Box as u, Typography as d, Grid as g } from "@mui/material";
import { i as v } from "../../styled-706f6038.js";
import { r as h } from "../../createSvgIcon-3ff6de40.js";
import { useMemo as C } from "react";
var a = {}, R = v;
Object.defineProperty(a, "__esModule", {
  value: !0
});
var c = a.default = void 0, _ = R(h()), y = m, D = (0, _.default)(/* @__PURE__ */ (0, y.jsx)("path", {
  d: "M18.3 5.71a.9959.9959 0 0 0-1.41 0L12 10.59 7.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
}), "CloseRounded");
c = a.default = D;
const S = ({ presetOptions: t, activePreset: s, onPresetChange: i, isLive: l, isRangeOnly: p }) => {
  const f = C(() => Math.ceil(t.length / 2), [t]);
  return /* @__PURE__ */ n(u, { sx: { display: "flex", flexDirection: "column" }, children: [
    /* @__PURE__ */ o(d, { sx: { fontSize: "13px", fontWeight: "600", lineHeight: "15px", color: "#878787", padding: "6px" }, children: l ? "Live Data Presets" : "Data Presets" }),
    /* @__PURE__ */ o(g, { container: !0, sx: { display: "grid", gridTemplateRows: `repeat(${f}, 1fr)`, gap: "4px", gridAutoFlow: "column", gridAutoColumns: "1fr 1fr" }, children: t.map((e) => {
      const r = e.value === s.value;
      return /* @__PURE__ */ n(
        u,
        {
          onClick: () => {
            r || i(e);
          },
          sx: {
            display: "flex",
            alignItems: "center",
            gap: "4px",
            cursor: r ? "default" : "pointer",
            padding: "6px",
            borderRadius: "4px",
            backgroundColor: r ? "#E9F0EE" : "transparent",
            width: "max-content",
            position: "relative"
          },
          children: [
            /* @__PURE__ */ o(d, { sx: { fontSize: "13px", fontWeight: "500", lineHeight: "15px", color: "#226A58" }, children: e.label }),
            r && l && !p && e.value !== "custom" && /* @__PURE__ */ o(
              c,
              {
                onClick: (x) => {
                  x.stopPropagation(), i({ label: "Custom", value: "custom" });
                },
                sx: { fontSize: "14px", color: "#226A58", cursor: "pointer" }
              }
            )
          ]
        },
        e.value
      );
    }) })
  ] });
};
export {
  S as LiveDataPresetsMenu
};
