import { jsx as o, jsxs as l } from "react/jsx-runtime";
import { FormControl as c, Select as f, MenuItem as b } from "@mui/material";
import { Grid as s } from "../Grid/Grid.js";
import { Typography as g } from "../Typography/Typography.js";
import { d as h } from "../../../KeyboardArrowDown-695f8c89.js";
const B = {
  customSelect: {
    borderRadius: "8px",
    color: "#5B5B5B !important",
    border: "1px solid #EFEFEF",
    backgroundColor: "#F7F7F7",
    gap: "8px",
    height: "32px",
    ".MuiFilledInput-input": {
      padding: "6px 32px 6px 16px",
      borderRadius: "8px !important",
      "&:focus": {
        background: "none"
      }
    },
    "&:before": {
      borderBottom: "none"
    },
    "&:after": {
      borderBottom: "none"
    },
    "&:hover:not(.Mui-disabled, .Mui-error):before": {
      borderBottom: "none",
      borderRadius: "8px"
    },
    ".MuiSelect-icon": {
      color: "#5B5B5B"
    },
    "& .MuiGrid-root": {
      fontSize: "12px",
      gap: "8px",
      lineHeight: "24px"
    }
  }
};
function E({
  id: t,
  DropdownIcon: i,
  options: n,
  placeholder: a,
  value: d,
  variant: p,
  sx: u,
  ...m
}) {
  return /* @__PURE__ */ o(c, { variant: p, hiddenLabel: !0, children: /* @__PURE__ */ o(
    f,
    {
      id: t,
      labelId: `${t}-label`,
      displayEmpty: !0,
      IconComponent: (r) => i ? /* @__PURE__ */ o(i, { ...r }) : /* @__PURE__ */ o(h, { ...r }),
      sx: { ...B.customSelect, ...u },
      renderValue: (r) => {
        if (!r)
          return a;
        const e = n.find((x) => x.value === r);
        return /* @__PURE__ */ l(
          s,
          {
            container: !0,
            flexDirection: "row",
            gap: 2,
            wrap: "nowrap",
            alignItems: "center",
            children: [
              e == null ? void 0 : e.icon,
              e == null ? void 0 : e.label
            ]
          }
        );
      },
      value: d,
      ...m,
      children: n.map((r) => /* @__PURE__ */ l(
        b,
        {
          value: r.value,
          sx: { display: "flex", gap: 1 },
          children: [
            r.icon,
            /* @__PURE__ */ o(g, { sx: { fontSize: "12px", fontWeight: 400 }, children: r.label })
          ]
        },
        r.id
      ))
    }
  ) });
}
export {
  E as Select
};
