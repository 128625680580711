/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */

import { RequestOptions } from "~/types/requestOptions";
import { authenticationService } from "./authentication-service";
import { AggregatedMetrics, Metrics } from "~/types/metrics";

const baseURL = "/api/v1/orch/";

export type MetricsAggQuery = {
  metrics: string[];
  interval: {
    startTime: number;
    endTime: number;
  };
  resolution: number;
  timezone?: number;
  queryKeys?: {
    key: string;
    value: number | string;
  }[];
};

export type MetricsQuery = MetricsAggQuery & {
  ids: string[];
  queryKeys?: {
    key: string;
    value: number | string;
  }[];
  /** Dependency for loading */
  showLoading?: boolean;
  aggregate?: boolean;
};

// type Read<T> = {[P in keyof T]: T[P]}

class APIService<T> {
  readonly url: string = baseURL;
  readonly token: string = authenticationService.currentUserValue?.token;

  constructor(objectType: string, token?: string) {
    this.url = baseURL + objectType;
    if (token) {
      this.token = token;
    }

    // function binding
    this.getAll = this.getAll.bind(this);
    this.create = this.create.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.getMetrics = this.getMetrics.bind(this);
    this.getAggregateMetrics = this.getAggregateMetrics.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.upload = this.upload.bind(this);
  }

  async getAll(queryString?: string) {
    const options: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    };
    
    let url = this.url;
    if (queryString) {
      url = url + "?" + queryString;
    }

    try {
      const response = await fetch(url, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async create(data: T) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(this.url, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async update(data: T, id?: string) {

    const options: RequestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: JSON.stringify(data),
    };
    try {
      // @ts-ignore
      const response = await fetch(`${this.url}/${id ?? data.id}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const _data = result?.data ?? result;
      return _data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async delete(id: string) {
    const options: RequestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    };
    try {
      const response = await fetch(`${this.url}/${id}`, options);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getAggregateMetrics(query: MetricsAggQuery) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: JSON.stringify(query),
    };
    try {
      const response = await fetch(`${this.url}/metrics/aggregate`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as AggregatedMetrics[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getMetrics(query: MetricsQuery) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
      body: JSON.stringify(query),
    };
    try {
      const response = await fetch(`${this.url}/metrics`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as Metrics[];
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async get(path: string) {
    const options: RequestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    };
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data as T;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async post<U = Response>(path: string, data?: U) {
    const options: RequestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.token,
      },
    };
    if (data) {
      options.body = JSON.stringify(data);
    }
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      if (data) {
        const text = await response.text();
        const result = text && JSON.parse(text);
        const data = result?.data ?? result;
        return data as T;
      }
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async upload(path: string, file: File, metaData?: any) {
    const formData = new FormData();
    formData.append("file", file);
    if (metaData) {
      formData.append("metaData", JSON.stringify(metaData));
    }
    const options: RequestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.token,
      },
      body: formData,
    };
    try {
      const response = await fetch(`${this.url}/${path}`, options);
      const text = await response.text();
      const result = text && JSON.parse(text);
      const data = result?.data ?? result;
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default APIService;
