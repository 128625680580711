import * as n from "react";
import { c as o, T as s, d as u } from "./styled-706f6038.js";
import { ThemeContext as r } from "@emotion/react";
function m(e) {
  return Object.keys(e).length === 0;
}
function c(e = null) {
  const t = n.useContext(r);
  return !t || m(t) ? e : t;
}
const a = o();
function f(e = a) {
  return c(e);
}
function T() {
  const e = f(u);
  return process.env.NODE_ENV !== "production" && n.useDebugValue(e), e[s] || e;
}
export {
  f as a,
  T as u
};
