var f = Object.defineProperty;
var S = (i, t, n) => t in i ? f(i, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : i[t] = n;
var l = (i, t, n) => (S(i, typeof t != "symbol" ? t + "" : t, n), n);
import { useState as c, useEffect as d } from "react";
const u = window.SpeechRecognition || window.webkitSpeechRecognition;
class g {
  constructor(t = "en-US") {
    l(this, "recognition");
    l(this, "listening", !1);
    this.recognition = new u(), this.recognition.lang = t;
  }
  static isSupported() {
    return !!u;
  }
  start() {
    this.listening = !0, this.recognition.start();
  }
  onStart(t) {
    this.listening = !0, this.recognition.onaudiostart = t;
  }
  onEnd(t) {
    this.listening = !1, this.recognition.onaudioend = t;
  }
  onError(t) {
    this.recognition.onerror = (n) => {
      t(n.error);
    };
  }
  stop() {
    this.recognition.stop(), this.listening = !1;
  }
  onResult(t) {
    this.recognition.onresult = (n) => {
      console.log(n.results);
      const e = n.results[n.results.length - 1][0].transcript;
      this.listening = !1, t(e);
    };
  }
}
const s = new g(), E = () => {
  const [i, t] = c(null), [n, e] = c(s.listening), [a, o] = c(null);
  d(() => {
    if (!g.isSupported()) {
      o("Speech recognition is not supported in this browser");
      return;
    }
    s.onStart(() => {
      o(null), t(null), e(!0);
    }), s.onResult((r) => {
      t(r), o(null);
    }), s.onError((r) => {
      o(r), t(null), e(!1);
    }), s.onEnd(() => {
      o(null), t(null), e(!1);
    });
  }, []);
  function h() {
    s.start();
  }
  function p() {
    s.stop();
  }
  return { transcript: i, error: a, startListening: h, stopListening: p, isListening: n };
};
export {
  E as useSpeechToText
};
