import { jsx as e, jsxs as a } from "react/jsx-runtime";
import { useChatbotContext as h } from "./hooks/index.js";
import { c as u } from "../../createSvgIcon-0ab806e0.js";
const f = u(/* @__PURE__ */ e("path", {
  d: "M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
}), "Mic"), C = ({
  placeholder: r = "Type your message here..."
}) => {
  const { prompt: n, setPrompt: o, send: d, isProcessing: t, speech: i, inputRef: l } = h(), c = (s) => {
    s.preventDefault();
    const p = n.trim();
    t || p && (d(n), o(""));
  };
  function m() {
    i.isListening ? i.stopListening() : i.startListening();
  }
  return /* @__PURE__ */ a("form", { className: "chatbot-footer", onSubmit: c, children: [
    /* @__PURE__ */ e("input", { disabled: t, className: "chatbot-input", value: n, type: "text", placeholder: r, ref: l, onChange: (s) => {
      o(s.target.value);
    } }),
    /* @__PURE__ */ a("div", { className: "chatbot-actions", children: [
      /* @__PURE__ */ e("div", { id: "record", className: `action ${i.isListening ? "animate-recording" : ""} ${t ? "disabled" : ""}`, onClick: m, children: /* @__PURE__ */ e(f, {}) }),
      /* @__PURE__ */ e("div", { id: "send", className: `action ${t ? "disabled" : ""}`, onClick: c, children: "➤" })
    ] })
  ] });
};
export {
  C as default
};
