var a = Object.defineProperty;
var g = (c, e, t) => e in c ? a(c, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : c[e] = t;
var l = (c, e, t) => (g(c, typeof e != "symbol" ? e + "" : e, t), t);
let s = 5;
class d {
  constructor(e) {
    l(this, "socket");
    l(this, "events");
    l(this, "type");
    this.socket = null, this.type = "text", this.events = {
      systemConecting: () => {
        console.log("Connecting to socket");
      },
      systemError: (t) => {
        console.error(t);
      },
      systemMessage: (t) => {
        console.log(t);
      },
      ...e
    };
  }
  connect(e, { token: t, type: o }) {
    var i, r;
    try {
      this.socket || (this.socket = new WebSocket(e), o && (this.type = o), (r = (i = this.events).systemConecting) == null || r.call(i), this.socket.onopen = () => {
        console.log("Socket connection established"), t && this.send("<<create_session>> " + t), s = 5;
      }, this.socket.onerror = (n) => {
        var h, k;
        (k = (h = this.events).systemError) == null || k.call(h, JSON.stringify(n));
      }, this.socket.onclose = () => {
        var n, h;
        (h = (n = this.events).systemMessage) == null || h.call(n, `Socket closed. Reconnecting in ${s} seconds`), this.socket = null, setTimeout(() => {
          this.connect(e, { token: t, type: o }), s = Math.min(s * 2, 60);
        }, s * 1e3);
      });
    } catch (n) {
      throw console.log(n), console.log(`'Error connecting to socket. Reconnecting in ${s} seconds`), this.socket = null, setTimeout(() => {
        this.connect(e, { token: t, type: o }), s = Math.min(s * 2, 60);
      }, s * 1e3), n;
    }
  }
  disconnect() {
    this.socket && (this.socket.close(), this.socket = null);
  }
  send(e) {
    var o, i;
    let t;
    switch (this.type) {
      case "text":
        t = e;
        break;
      case "json":
        t = JSON.stringify(e);
        break;
      case "binary":
        t = new Blob([JSON.stringify(e)], { type: "application/json" });
        break;
      default:
        t = e;
        break;
    }
    try {
      this.socket && this.socket.send(t);
    } catch (r) {
      throw console.log(r), (i = (o = this.events).systemError) == null || i.call(o, JSON.stringify(r)), "Error sending message to socket";
    }
  }
  on(e, t) {
    this.socket && this.socket.addEventListener(e, t);
  }
}
export {
  d as default
};
